import ProcessCard from "./process-card/process-card";

const processBoxLink = process.env.REACT_APP_PROCESSBOX_SITE;

type Props = {
  processesObj: {
    processes: any[],
    total: number
  },
  [key: string]: any
};

const ProcessesGrid = (props: Props) => {

  const { processesObj } = props;

  return (<>
    <div className="processes-grid grid">
      {processesObj.processes?.map((process: any) => {
        return (
          <div key={process._id} className="d-flex justify-content-center">
            <a
              className="card-link"
              href={`${processBoxLink}p/${process.identifier}-${process._id}/`}
            >
              <ProcessCard process={process} />
            </a>
          </div>
        );
      })}
    </div>
  </>);
}

export default ProcessesGrid;