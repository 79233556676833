import { Card } from 'react-bootstrap';
import { ClassId } from '../../../../models/constants';
import { getImageUrl } from '../../../../services/api';

import yellowStar from "../../../../assets/svg/yellowStar.svg";
import greyStar from "../../../../assets/svg/greyStar.svg";

import icon_DownloadButton from "../../../../assets/streamline-icon-download-bottom@14x14.svg";
import icon_View from "../../../../assets/streamline-icon-view-1@14x14.svg";

const ProcessCard = (props: any) => {

  const { process } = props;

  function getProcessStars(process: any) {
    let stars: JSX.Element[] = [];
    if (process.rating) {
      for (let i = 0; i < 5; i++) {
        if (i <= process.rating - 1) {
          stars.push(
            <img
              className="stars"
              src={yellowStar}
              alt="Star"
              key={i}
            />
          );
        } else {
          stars.push(
            <img
              className="stars"
              src={greyStar}
              alt="Star"
              key={i}
            />
          );
        }
      }
    } else {
      for (let i = 0; i < 5; i++) {
        stars.push(
          <img
            className="stars"
            src={greyStar}
            alt="Star"
            key={i}
          />
        );
      }
    }
    return stars;
  }

  return (<>
    <Card className="process-card m-auto">
      <Card.Img
        // className="process-view-card-image"
        src={getImageUrl(
          ClassId.Process,
          process._id,
          process.images[0]._id
        )}
        alt={`${process.name} - Modelagem BPMN`}
      ></Card.Img>

      <Card.Body className="card-footer-proc d-flex align-items-center">
        <div className="process-view-card-body px-3">
          <Card.Title className="proc-text">{process.description}</Card.Title>
          <Card.Subtitle className="pac-text">{process.category.name}</Card.Subtitle>
        </div>
      </Card.Body>

      <Card.Body className="card-footer-container d-flex align-items-center">
        <div className="card-footer-items d-flex px-3 justify-content-between w-100">
          <div className="assessment d-inline">
            <div className="d-inline">{getProcessStars(process)}</div>
          </div>

          <div>
            <div className="assessment d-inline">
              <img src={icon_View} width="14px"/>
              <div className="card-footer-proc-text d-inline">{process.viewCount}</div>
            </div>

            <div className="assessment ml-2 d-inline">
              <img src={icon_DownloadButton} width="14px" />
              <div className="card-footer-proc-text d-inline">{process.downloadCount === null ? 0 : process.downloadCount}</div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  </>);
}

export default ProcessCard;