const LinkCard = (props: any) => {
    const {
        header, link, linkText, description
    } = props;

    return (<>
        <div className="py-4 link-card">
            <h2>{header}</h2>
            <a href={link}>{linkText}</a>
            <br/>
            <small>{description}</small>
        </div>
    </>);
}

export default LinkCard;