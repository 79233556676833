import LinkCard from '../../ferramentas-de-modelagem/links-list/link-card/link-card'

const ProcessBoxCard = (props: any) => {
    return (<>
        <div className="mt-4">
            <small className="related-links">Powered by</small>
            <div className="mt-2 px-4" style={{ backgroundColor: 'white' }}>
                <LinkCard
                    header="ProcessBox - Editor bpmn online gratuito"
                    link="https://www.processbox.org/"
                    linkText="https://www.processbox.org/"
                    description="Ferramenta online de edição BPMN. Crie, exporte, compartilhe e encontre processos de negócio criado por profissionais de todos os segmentos do mercado."
                />
            </div>
        </div>
    </>);
};

export default ProcessBoxCard;