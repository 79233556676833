import { Container, Tabs, Tab,Nav } from "react-bootstrap";
import FerramentasDeModelagem from "../../components/ferramentas-de-modelagem/ferramentas-de-modelagem";
import ProcessosCorporativos from "../../components/processos-corporativos/processos-corporativos";
import ProcessBoxCard from '../../components/processos-corporativos/process-box-card/process-box-card';

const Home = (props: any) => {

  return (
    <Tab.Container defaultActiveKey="tab1">
      <header>
        <h1 className="text-center text-primary pt-5 pb-3">Bizagi fluxograma</h1>
        <Nav variant="tabs"  className="justify-content-center">
          <Nav.Item>
            <Nav.Link className="text-secondary p-4" eventKey="tab1">Processos corporativos</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="text-secondary p-4" eventKey="tab2">Ferramentas de modelagem</Nav.Link>
          </Nav.Item>
        </Nav>
      </header>
      <Tab.Content className="py-5">
        <Tab.Pane eventKey="tab1">
          <Container>
            <ProcessosCorporativos />
          </Container>
          <Container className="links-container">
            <ProcessBoxCard/>
          </Container>
        </Tab.Pane>
        <Tab.Pane eventKey="tab2">
          <Container className="links-container">
            <FerramentasDeModelagem />
          </Container>
        </Tab.Pane>
      </Tab.Content>

    </Tab.Container>
  );
}

export default Home;