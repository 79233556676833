import { createBrowserHistory } from "history";

import { Route, Router, Switch } from 'react-router-dom';
import Home from "./pages/home/home";
const history =  createBrowserHistory();

export default function Routes() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path="/legal/:page" exact component={Terms} />
          <Route path="/login" exact component={Login} />
          <Route path="/p/:pIdentifier-:pId([0-9a-f]{24})" exact component={Process} />
          <Route path="/c/:cIdentifier-:cId([0-9a-f]{24})" exact component={Categories} />
          <Route path="/search" exact component={Search} />
          <Route path="/user-:uIdentifier" exact component={Author} />
          <RoutePrivate path="/upload" exact component={Upload} />
          <RoutePrivate path= "/edit/:pId([0-9a-f]{24})" exact component={Upload}/> */}
        </Switch>
      </Router>
    );
  }