import { useEffect, useState } from "react";
import { axiosAPI } from "../../services/api";
import ProcessesGrid from "./processes-grid/processes-grid"
import SearchBar from "./search-bar/search-bar";
import { Button } from 'react-bootstrap';

const processBoxLink = process.env.REACT_APP_PROCESSBOX_SITE;

const ProcessosCorporativos = (props: any) => {

  const [processesObj, setProcessesObj] = useState<{
    processes: any[],
    total: number
  }>();

  useEffect(() => {
    loadProcesses();
  }, [])

  function loadProcesses() {
    let filters: any = {
      sort: {
        viewCount: { order: 'desc' },
      },
      query: {
        bool: {
          must: [
            { term: { "category.enabled": true } },
            { term: { "isPublished": true } }
          ]
        }
      }
    };

    let params = {
      pageIndex: 0,
      pageSize: 9,
    };

    axiosAPI.post('getAllProcess', { filters, params })
      .then(response => response.status === 200 ? response.data.result : {})
      .then(processesObj => {
        setProcessesObj(processesObj);
      });
  }

  return (<>
    <SearchBar className="pb-5" />
    {processesObj ? <ProcessesGrid processesObj={processesObj} /> : null}
    <div className="d-flex justify-content-center">
      <Button as="a" variant="primary" className="mt-4" href={processBoxLink}>Ver Mais</Button>
    </div>
  </>);
};

export default ProcessosCorporativos;