import axios from "axios";

export function getImageUrl(classId: string, objectId: string, photoId: string) {
    let accessToken = process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN;
    let baseURL = `${process.env.REACT_APP_SYDLE_ONE_API}processBox`;
    let link = `${baseURL}/_classId/${classId}/_download/${objectId}/${photoId}?accessToken=${accessToken}`;
    return link;
}

export const axiosAPI = axios.create({
    baseURL: process.env.REACT_APP_SYDLE_ONE_API + 'processBox/com.sydle.processbox/processBoxAPI/',
    headers: {
      "Authorization": `Bearer ${process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN}`,
      "Content-Type": "application/json",
    }
  });

export default {
    getImageUrl,
    axiosAPI
};