
import LinkCard from './link-card/link-card';

const LinksList = (props: any) => {
    return (<>
        <div className="px-4" style={{backgroundColor: 'white'}}>
            <LinkCard
                header="BPM - Automatização de processos | SYDLE"
                link="https://www.sydle.com/bpm"
                linkText="https://www.sydle.com › bpm"
                description="Processos automatizados, flexíveis e orientados a resultados para negócios escaláveis com qualidade."
            />

            <LinkCard
                header="Download do Bizagi BPMN Modeler"
                link="https://www.bizagi.com/pt/produtos/bpm-suite/modeler"
                linkText="https://www.bizagi.com/pt/produtos/bpm-suite/modeler"
                description="Descarregue a nossa reconhecida ferramenta gratuita para documentar e otimizar os processos de negócio."
            />

            <LinkCard
                header="ECM - Gestão de documentos | SYDLE"
                link="https://www.sydle.com/ecm"
                linkText="https://www.sydle.com › ecm"
                description="Gestão integrada de conteúdos combinados com processos para gerar e manter grandes volumes de dados estruturados, atualizados e confiáveis."
            />

            <LinkCard
                header="Analytics - Informação em tempo real | SYDLE"
                link="https://www.sydle.com/analytics"
                linkText="https://www.sydle.com › analytics"
                description="Análise de dados em tempo real – em gráficos ou extrações – para facilitar a gestão à vista, fornecer insights e apoiar a tomada de decisão."
            />
        </div>
    </>);
}

export default LinksList;