import { useState, useEffect, KeyboardEvent } from 'react';
import { Button } from 'react-bootstrap';

import icon_Search from "../../../assets/streamline-icon-search@23x23.svg";

const processBoxLink = process.env.REACT_APP_PROCESSBOX_SITE;

const SearchBar = (props: any) => {

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (inputRef) {
            let val = inputRef.value;
            if (val) {
                let link = `${processBoxLink}search?q=${val}`;
                window.location.assign(link);
            }
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleClick();
        }
    };

    return (<>
        <div className={'search-box d-flex ' + (props?.className || '')}>
            <input
                ref={setInputRef}
                onKeyDown={handleKeyDown}
                className="mr-2 flex-fill"
                type="text"
                name="search-bar"
                placeholder="Encontre processos corporativos"
            />

            <Button variant="primary" name="search-button" onClick={handleClick}>
                <img src={icon_Search} width="22.5px" />
            </Button>
        </div>
    </>);
}

export default SearchBar;